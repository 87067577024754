<template>
  <div>
    <b-card no-body class="account-top">
      <b-card-body>
        <div class="account-title">
          <feather-icon
              class="icon-account"
              icon="TrendingUpIcon"
          />
          <span class="text-account">
            {{ $t("Users Feedback Details") }}
          </span>
        </div>
      </b-card-body>
    </b-card>
    <div>
      <b-card>
    <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          height="300"
          :options="chartOptionsBar"
          :series="seriesBar"
        />
  </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from "vue-apexcharts";


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      seriesBar: [],
      chartOptionsBar: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: false,
            columnWidth: "50%",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -25,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        colors: ["#008ffb", "#E91E63", "#e5eaef"],
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {},
        fill: {
          opacity: 1,
        },
        tooltip: {},
        legend: {
          show: false,
        },
        noData: {
          text: "No Data",
        },
      }
    }
  },
  methods: {
    getData(){
      this.$store.dispatch("users/getUsersFeedback", { context: this })
      .then((resp) => {
        let dictValues = {}
        resp.data.forEach(element => {
          dictValues[element.reason_choice] = (dictValues[element.reason_choice] || 0) + 1
        });
        Object.keys(dictValues).forEach( title => {
          this.chartOptionsBar.xaxis.categories.push(title)
        }
        )
        this.seriesBar.push({name: "values",data: Object.values(dictValues)})
    })
    }
  },
  created()
  {
    this.getData()
  }
}
</script>
<style lang="scss">
@import '@core/scss/base/pages/page-company.scss';
</style>